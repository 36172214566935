// import { RootState, AppThunk } from "../../redux/store";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";

const { apiBaseUrl } = config;

interface rosterDetail {
  id: string;
  name: string;
  slackChannel: string;
  messageTemplate: string;
  people?: any[];
  schedule: string;
}
export interface RosterDetailState {
  isLoading: boolean;
  roster: rosterDetail;
  rosters: any;
}

export const initialState: RosterDetailState = {
  isLoading: false,
  roster: {
    id: "new",
    name: "",
    slackChannel: "",
    messageTemplate: "",
    people: [],
    schedule: "",
  },
  rosters: {},
};

export const updateRosterThunk = createAsyncThunk(
  "roster/updateRoster",
  async (payload: any, thunkAPI) => {
    const { id, rosterName, messageTemplate, schedule, rosterPeople } = payload;

    const body = {
      name: rosterName,
      schedule,
      messageTemplate,
      channelId: 20, // *********************** need to fix
      channelHandle: "mock", // *************** need to fix
      rosterPeople,
    };

    const path = `${apiBaseUrl}/roster/${id}`;

    let response = await axios.put(path, body);

    // transform here if required.

    return response;
  }
);

export const createRosterThunk = createAsyncThunk(
  "roster/createRoster",
  async (payload: any, thunkAPI) => {
    const {
      rosterName,
      messageTemplate,
      slackChannel,
      navigate,
      schedule,
      people,
    } = payload;

    const body = {
      name: rosterName,
      channelHandle: slackChannel,
      schedule: schedule,
      messageTemplate: messageTemplate,
      rosterPeople: people,
    };

    const path = `${apiBaseUrl}/roster`;

    let response = await axios.post(path, body);

    var x = {
      data: response.data,
      nav: navigate,
    };

    return x;
  }
);

export const loadRosterThunk = createAsyncThunk(
  "roster/loadRoster",
  async (id: any, thunkAPI) => {
    const path = `${apiBaseUrl}/roster/${id}`;

    try {
      let response = await axios.get(path);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }
);

export const addPersonThunk = createAsyncThunk(
  "roster/addPerson",
  async (newPerson: any, thunkAPI) => {
    return newPerson;
  }
);

export const deletePersonThunk = createAsyncThunk(
  "roster/deletePerson",
  async (rosterPeople: any, thunkAPI) => {
    return rosterPeople;
  }
);

export const rosterDetailSlice = createSlice({
  name: "rosterDetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRosterThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(createRosterThunk.fulfilled, (state, action) => {
      const id = action.payload.data.id;
      state.rosters[action.payload.data.id] = action.payload.data;
      action.payload.nav(`../roster/${id}`);
      state.isLoading = false;
    });
    builder.addCase(loadRosterThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loadRosterThunk.fulfilled, (state, action) => {
      const payload = action.payload;
      state.rosters = payload;
      state.roster.id = payload.id;
      state.roster.name = payload.name;
      state.roster.people = payload.people;
      state.roster.messageTemplate = payload.messageTemplate;
      state.roster.slackChannel = payload.channelHandle;
      state.roster.schedule = payload.schedule;
      state.isLoading = false;
    });
    builder.addCase(addPersonThunk.fulfilled, (state, action) => {
      state.roster.people = action.payload;
    });
    builder.addCase(deletePersonThunk.fulfilled, (state, action) => {
      state.roster.people = action.payload;
    });
    builder.addCase(updateRosterThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateRosterThunk.fulfilled, (state, action: any) => {
      state.isLoading = false;

      const { name, messageTemplate, schedule } = action.payload;
      state.roster.name = name;
      state.roster.messageTemplate = messageTemplate;
      state.roster.schedule = schedule;
    });
  },
});

export default rosterDetailSlice.reducer;
