import React, { useEffect } from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { rosterListThunk } from "./rosterListSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Spinner from 'react-bootstrap/Spinner';

function RosterList() {
  const dispatch = useAppDispatch();
  const rosterList = useAppSelector((state: any) => state.rosterList)
  const { rosters, isLoading } = rosterList

  useEffect(() => {
    dispatch(rosterListThunk())
  }, [dispatch])

  return <div>
    <Container>
      <Row>
        <Col>
          <h1>Roster List</h1>
        </Col>
      </Row>
      <ListGroup>
        {isLoading ?
          <ListGroup.Item>
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </ListGroup.Item>
          : rosters.map((roster: any) => {
            return (
              <ListGroup.Item key={roster.id}>
                <a href={'/roster/'+roster.id}>{roster.name}</a>
              </ListGroup.Item>
            )
          })}
      </ListGroup>
      <a href="/roster/new">
        Add Roster
      </a>
    </Container>
  </div>
}

export default RosterList;