import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "react-bootstrap/Button";
import { FaTrash } from 'react-icons/fa';
import {
  addPersonThunk,
  createRosterThunk,
  deletePersonThunk,
  loadRosterThunk,
  updateRosterThunk,
} from "../rosterDetailSlice";
import { useAppDispatch } from "../../../app/hooks";
import Table from "react-bootstrap/Table";
import { useParams, useNavigate } from "react-router-dom";
import { Cron } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import "./RosterDetail.css";
import { getId, getIsCreating } from "../rosterDetailSelectors";

interface RosterDetailProps {
  roster: any;
  isCreating: boolean;
  id: string;
}

function RosterDetail(props: RosterDetailProps) {
  const dispatch = useAppDispatch();
  var { rosterId } = useParams();
  const navigate = useNavigate();

  if (!rosterId) rosterId = "new";
  const currentRoster = props.roster;

  const [rosterName, setRosterName] = useState(currentRoster?.rosterName ?? "");
  const [messageTemplate, setMessageTemplate] = useState(
    currentRoster?.messageTemplate ?? ""
  );

  const [slackChannel, setSlackChannel] = useState(
    currentRoster?.slackChannel ?? ""
  );

  const [schedule, setSchedule] = useState(
    currentRoster?.schedule ?? "0 10 * * Mon"
  );

  const [people, setPeople] = useState(currentRoster?.people);

  const setPersonName = (name: any, id: any) => {
    setPeople(
      evaluatePeople().map((person: any) =>
        person.id === id ? { ...person, fullname: name } : { ...person }
      )
    );
  };

  const setPersonSlackhandle = (handle: any, id: any) => {
    setPeople(
      evaluatePeople().map((person: any) =>
        person.id === id ? { ...person, slackhandle: handle } : { ...person }
      )
    );
    return true;
  };

  const handleSave = () => {
    var roster: any = {
      id: props.id,
      rosterName,
      messageTemplate,
      schedule,
      slackChannel, // can add this back soon
      navigate,
    };

    let isFirst = props.isCreating ? 0 : 1;

    roster.rosterPeople = evaluatePeople().map((person: any) => {
      return {
        fullname: person.fullname,
        slackHandle: person.slackhandle,
        isStartingPerson: isFirst++ === 0,
        id: person.id,
      };
    });
    roster.slackChannel = slackChannel;

    if (props.isCreating) {
      dispatch(createRosterThunk(roster));
    } else {
      dispatch(updateRosterThunk(roster));
    }
  };

  const handleAddPerson = () => {
    dispatch(
      addPersonThunk([
        ...evaluatePeople(),
        { id: "new", fullname: "new name", slackhandle: "new handle" },
      ])
    );
  };

  const handleDeletePerson = (id: number) => {
    dispatch(
      deletePersonThunk(
        evaluatePeople().filter((p:any) => {return p.id !== id})
      )
    );
  };
  

  const evaluateRosterName = () => {
    return rosterName.length > 0 ? rosterName ?? "" : currentRoster?.name;
  };

  const evaluateMessageTemplate = () => {
    return messageTemplate.length > 0
      ? messageTemplate ?? ""
      : currentRoster?.messageTemplate;
  };

  const evaluateSlackChannel = () => {
    return slackChannel.length > 0
      ? slackChannel ?? ""
      : currentRoster?.slackChannel;
  };

  const evaluateSchedule = () => {
    return schedule.length > 0 ? schedule : currentRoster?.schedule;
  };

  const evaluatePeople = () => {
    return people.length > 0 ? people : currentRoster?.people;
  };

  useEffect(() => {
    dispatch(loadRosterThunk(rosterId));
  }, [props.roster.id]);

  return (
    <div className="rosterDetail-container">
      <div className="details-container">
        <span>
          <h1>{rosterId}</h1>
          <label>Roster Name:</label>
          <input
            value={evaluateRosterName()}
            onChange={(e) => setRosterName(e.target.value)}
          ></input>
        </span>
        <br />
        <span>
          <label>Message Template:</label>
          <input
            value={evaluateMessageTemplate()}
            onChange={(e) => setMessageTemplate(e.target.value)}
          ></input>
        </span>
        <br />
        <span>
          <label>Slack Channel:</label>
          <input
            value={evaluateSlackChannel()}
            onChange={(e) => setSlackChannel(e.target.value)}
          ></input>
        </span>
        <br />
        <span className="scheduleContainer">
          <label>Schedule:</label>
          <Cron value={evaluateSchedule()} setValue={setSchedule}></Cron>
        </span>
      </div>
      <div className="people-container">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Slack Handle</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {evaluatePeople().map((person: any) => {
              return (
                <tr key={person.id}>
                  <td>
                    <input
                      value={person.fullname}
                      onChange={(e) => setPersonName(e.target.value, person.id)}
                    ></input>
                  </td>
                  <td>
                    <input
                      value={person.slackhandle}
                      onChange={(e) =>
                        setPersonSlackhandle(e.target.value, person.id)
                      }
                    ></input>
                  </td>
                  <td><Button className="icon-trash" variant="outline-danger" onClick={() => handleDeletePerson(person.id)}><FaTrash></FaTrash></Button></td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Button variant="secondary" onClick={() => handleAddPerson()}>
        Add Person
      </Button>
      &nbsp;
      <Button variant="success" onClick={() => handleSave()}>
        Save
      </Button>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    roster: state.rosterDetail.roster,
    isCreating: getIsCreating(state),
    id: getId(state),
  };
};

export default connect(mapStateToProps)(RosterDetail);
