import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../config";

const { apiBaseUrl } = config;

export interface RosterListState {
  isLoading: boolean;
  rosters: any[];
}

export const initialState: RosterListState = {
  isLoading: false,
  rosters: [],
};

export const rosterListThunk = createAsyncThunk(
  "roster/rosterList",
  async () => {
    try {
      const path = `${apiBaseUrl}/roster`;
      const response = await axios.get(path);
      if (response.status === 200) {
        return response.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const rosterDetailSlice = createSlice({
  name: "rosterList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(rosterListThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(rosterListThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.rosters = action.payload;
    });
  },
});

export default rosterDetailSlice.reducer;
