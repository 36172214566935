import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Health from "./pages/Health";
import NotFound from "./pages/NotFound";
import Home from "./pages/Home";
import RosterDetail from "./features/RosterDetail/components/RosterDetail";
import RosterList from "./features/RosterList/RosterList";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/health" element={<Health />} />
        <Route path="/roster" element={<RosterList />} />
        <Route path="/rosters" element={<RosterList />} />
        <Route path="/roster/:rosterId" element={<RosterDetail />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
